import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import CustomBackButton from '../../CustomComponents/CustomBackButton';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep6() {
  const { trigger, handleSubmit, formState } = useFormContext();
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const { errors } = formState;
  const propertyvalue_highRef = useRef();
  const propValue = Math.floor(formData.propertyvalue / 1000);
  const [sliderValue, setSliderValue] = useState((propValue-50));
  const [maxSliderValue, setMaxSliderValue] = useState(propValue);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue + 50);
    setMaxSliderValue(newValue + 100);
  };
  const handleClickContinue = handleSubmit(async (_data) => {
    updateFormData({
      propertyvalue: `${maxSliderValue}000`,
    });
    const isValid = await trigger(['propertyvalue']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        propertyvalue: propertyvalue_highRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  
  return (
    <div id="question6" style={{ margin: '50px auto 0' }}>
      <Typography id="home_value" gutterBottom>
        Estimate House Value
      </Typography>
      <Slider
        value={sliderValue}
        ref={propertyvalue_highRef}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="estimated_home_value"
        min={100}
        max={1500}
        step={50}
      />
      <Typography>{`$${sliderValue}K to $${maxSliderValue}K`}</Typography>
      <input
        onClick={handleClickContinue}
        className="form-step__btn"
        type="button"
        value="Next"
      />
       <CustomBackButton/>
    </div>
  );
}

export default FormStep6;
