import {
  CustomInput,
  CustomPhoneInput,
  CustomSubmit,
} from '../../CustomComponents';
import {
  PHONE_ERROR_MESSAGE,
  US_PHONE_REGEX,
  ADDRESS_ERROR_MESSAGE
} from '../../../lib/constants';
import { validatePhoneNumber } from '../../../lib/utils';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import CustomBackButton from '../../CustomComponents/CustomBackButton';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep11() {
  const { register } = useFormContext();
  const { updateFormData, formData } = useFormDataContext();
  const {city, state, zip} = formData;

  useScrollToTop();

  return (
    <>
      <div id="question11">
        <div className="input-wrap">
          <label>Street Address</label>
          <CustomInput
            type="text"
            placeholder="Street Address"
            name="address"
            register={register}
            onInputChange={updateFormData}
            value={formData.address}
            validation={{
              required: ADDRESS_ERROR_MESSAGE
            }}
          />
          <label className='street-info-label'>{city}, {state}, {zip}</label>
        </div>
        <div className="input-wrap">
          <label>Phone Number</label>
          <CustomPhoneInput
            name="phone"
            placeholder="Phone Number"
            mask={US_PHONE_REGEX}
            autoComplete="off"
            register={register}
            onInputChange={updateFormData}
            prePopValue={formData.phone}
            validation={{
              required: PHONE_ERROR_MESSAGE,
              validate: validatePhoneNumber,
            }}
          />
        </div>
        <CustomSubmit />
        <CustomBackButton/>
      </div>
    </>
  );
}

export default FormStep11;
